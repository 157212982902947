<template>
  <div>
    <div class="page inner-container acct">
      <editAccount :user="isLoggedIn"></editAccount>
      <collectionList :user="getUser"></collectionList>
    </div>
  </div>
</template>

<script>
import { authHeader } from "@/helpers/authHeader";
import { mapState, mapActions, mapGetters } from "vuex";

import editAccount from "@/views/Account/parts/editAccount";
import collectionList from "@/views/Account/parts/collectionList";
export default {
  name: "AccountIndex",
  components: { editAccount, collectionList },
  data() {
    return {};
  },
  beforeMount() {
    // if (!this.userDate) {
    //   this.$router.push("/login");
    // }
  },
  computed: {
    ...mapGetters("common", ["isLoggedIn", "getUser"])
  }
};
</script>

<style lang="scss">
.acct {
  h2 {
    @include typo(bigTit);
    margin-bottom: 15px;
    @include respond-to(screen-sm) {
      margin-bottom: 20px;
    }
  }
  &--row {
    margin-bottom: 40px;
    @include respond-to(screen-sm) {
      margin-bottom: 80px;
    }
  }
  &--info {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    @include respond-to(screen-sm) {
      display: flex;
    }
    &--item {
      margin-bottom: 20px;
      @include respond-to(screen-sm) {
        margin-right: 120px;
        margin-bottom: 30px;
      }
    }
    span {
      margin-bottom: 5px;
      display: block;
    }
    h4 {
      font-size: 18px;
      font-weight: 400;
      @include respond-to(screen-sm) {
        font-size: 20px;
      }
    }
  }
  .ft-item {
    border: 1px solid $color-black;
    position: relative;
    overflow: hidden;
    // padding-top: 67%;
    margin-bottom: 30px;
    height: 300px;
    &--wrap {
      // padding: 100px;
    }
    &--inItem {
      @include center(xy);
      font-size: 22px;
      .plus {
        margin: auto;
        width: 25px;
        height: 2px;
        background: black;
        // transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        // border-radius: 2px;
        &:after {
          content: "";
          position: absolute;
          width: 25px;
          height: 2px;
          background: black;
          border-radius: 2px;
          transform: rotate(90deg);
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        }
      }
      p {
        margin-top: 20px;
      }
    }
  }
  .edit {
    &-trigger {
      text-decoration: underline;
    }
  }
  // .edit {
  //   @include transition(all 300ms ease-in-out);
  //   text-decoration: underline;
  //   font-size: 16px;
  //   &:hover {
  //     opacity: 0.7;
  //   }
  // }
}
</style>
